<template>
  <div class="section_our_solution gaphead" style="padding-top: 10%">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="our_solution_category">
          <div class="solution_cards_box sol_card_top_3">
            <div
              class="solution_card"
              @click.prevent="redirectToLink(items[0].link)"
            >
              <div class="hover_color_bubble"></div>
              <div class="so_top_icon">&nbsp;</div>
              <div class="solu_title">
                <h3>{{ items[0].title }}</h3>
              </div>
              <div class="solu_description">
                <p>{{ items[0].description }}</p>
                <button
                  type="button"
                  class="read_more_btn"
                  @click.prevent="redirectToLink(items[0].link)"
                >
                  {{ items[0].buttontext }}
                </button>
              </div>
            </div>
          </div>
          <div class="solution_cards_box sol_card_top_3">
            <div
              class="solution_card"
              @click.prevent="redirectToLink(items[1].link)"
            >
              <div class="hover_color_bubble"></div>
              <div class="so_top_icon">&nbsp;</div>
              <div class="solu_title">
                <h3>{{ items[1].title }}</h3>
              </div>
              <div class="solu_description">
                <p>{{ items[1].description }}</p>
                <button
                  type="button"
                  class="read_more_btn"
                  @click.prevent="redirectToLink(items[1].link)"
                >
                  {{ items[1].buttontext }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login-selection",
  data() {
    return {
      items: [
        {
          title: "Login",
          description:
            "Click or tap here to login as Internal Beam Suntory Employee or External Partner / Contractor.",
          buttontext: "Login Here",
          link: this.$func.loginUrl,
        },
        {
          title: "Register",
          description:
            "Don't have an account? Then this is the section for you to register yourself.",
          buttontext: "Register Now",
          link: "/register",
        },
      ],
    };
  },
  methods: {
    redirectToLink: function (link) {
      const _vm = this;
      if (link.includes("https://") || link.includes("http://")) {
        window.location.href = link;
      } else {
        _vm.$router.push({
          path: link,
        });
      }
    },
  },
};
</script>

<style scoped>
.section_our_solution .row {
  align-items: center;
}
.our_solution_category {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.our_solution_category .solution_cards_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.solution_cards_box .solution_card {
  flex: 0 50%;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
    0 5px 15px 0 rgba(37, 44, 97, 0.15);
  border-radius: 15px;
  margin: 8px;
  padding: 1px 15px 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 285px;
  transition: 0.7s;
  cursor: pointer !important;
}
.solution_cards_box .solution_card:hover {
  background: #309df0;
  color: #fff;
  transform: scale(1.1);
  z-index: 9;
}
.solution_cards_box .solution_card:hover::before {
  background: rgb(85 108 214 / 10%);
}
.solution_cards_box .solution_card:hover .solu_title h3,
.solution_cards_box .solution_card:hover .solu_description p {
  color: #fff;
}
.solution_cards_box .solution_card:before {
  content: "";
  position: absolute;
  background: rgb(85 108 214 / 5%);
  width: 170px;
  height: 400px;
  z-index: -1;
  transform: rotate(42deg);
  right: -56px;
  top: -23px;
  border-radius: 35px;
}
.solution_cards_box .solution_card:hover .solu_description button {
  background: #fff !important;
  color: #309df0;
}
.solution_card .so_top_icon {
}
.solution_card .solu_title h3 {
  color: #8e0006;
  font-size: 1.7rem;
  margin-top: 13px;
  margin-bottom: 13px;
  /*font-family: Helvetica Neue !important;*/
  font-family: Oswald !important;
}
.solution_card .solu_description p {
  font-size: 16px;
  margin-bottom: 15px;
  /*font-family: Helvetica Neue !important;*/
  font-family: Oswald !important;
}
.solution_card .solu_description button {
  border: 0;
  border-radius: 15px;
  background: linear-gradient(
    140deg,
    #42c3ca 0%,
    #42c3ca 50%,
    #42c3cac7 75%
  ) !important;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  padding: 5px 16px;
}
.our_solution_content h1 {
  text-transform: capitalize;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}
.our_solution_content p {
}
.hover_color_bubble {
  position: absolute;
  background: rgb(54 81 207 / 15%);
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -1;
  top: 16rem;
  border-radius: 50%;
  transform: rotate(-36deg);
  left: -18rem;
  transition: 0.7s;
}
.solution_cards_box .solution_card:hover .hover_color_bubble {
  top: 0rem;
}
.solution_cards_box .solution_card .so_top_icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: transparent;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solution_cards_box .solution_card .so_top_icon img {
  width: 40px;
  height: 50px;
  object-fit: contain;
}
@media screen and (min-width: 320px) {
  .sol_card_top_3 {
    position: relative;
    top: -3rem;
  }
  .our_solution_category {
    width: 100%;
    margin: 0 auto;
  }
  .our_solution_category .solution_cards_box {
    flex: auto;
  }
}
@media only screen and (min-width: 768px) {
  .our_solution_category .solution_cards_box {
    flex: 1;
  }
}
@media only screen and (min-width: 1024px) {
  .sol_card_top_3 {
    position: relative;
    top: -3rem;
  }
  .our_solution_category {
    width: 50%;
    margin: 0 auto;
  }
}
</style>
